<template>
    <Dropdown
        v-model="selectedYear"
        :options="years"
        optionLabel="label"
        optionValue="value"
        optionDisabled="disabled"
        :loading="loading"
        :disabled="loading"
        :autoOptionFocus="false"
        :showToggleAll="false"
        :selection-limit="1"
    />
</template>

<script lang="ts" setup>
import { PropType, ref, watch } from 'vue';
import { PaginatorFilter } from '../../composition/paginator';
import { DateTime } from 'luxon';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    filter: {
        type: Object as PropType<PaginatorFilter>,
        required: true,
    },
});

const { isActive, countActive, selectedFilters, getSelectedTermIdsByTaxonomy, resetFilter, setTaxonomyFilter } = props.filter;
const paginator = props.filter.paginator;
const loading = paginator.loading;
const { t: $t } = useI18n();

const years = (() => {
    const _years = [
        {
            label: $t('all year') as number | string,
            value: false as
                | false
                | {
                      from?: number;
                      to?: number;
                      year?: number;
                  },
        },
    ];

    // Let's generate an array for the last 5 years
    for (let i = 0; i <= 5; i++) {
        // The year of the current loop.
        const year = DateTime.now().year - i;

        if (i === 5) {
            // Everything before 5 ago
            _years.push({
                label: $t('dates_before') + ' ' + (year + 1),
                value: {
                    to: year + 1,
                },
            });
        } else {
            // Between current year and 5th last year
            _years.push({
                label: year,
                value: {
                    year,
                },
            });
        }
    }

    return _years;
})();

const selectedYear = ref(years[0].value);

watch(selectedYear, (value) => {
    if (value === false) {
        paginator.resetDates();
        return;
    }

    if (Object.hasOwn(value, 'year')) {
        paginator.setPublishedInYear(value.year);
    }

    if (Object.hasOwn(value, 'from')) {
        paginator.setFromDate(
            DateTime.fromObject({
                year: value.from,
                month: 1,
                day: 1,
            })
        );
    }

    if (Object.hasOwn(value, 'to')) {
        paginator.setFromDate(
            DateTime.fromObject({
                year: value.to,
                month: 12,
                day: 31,
            })
        );
    }
});
</script>
