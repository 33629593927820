<template>
	<article class="graphic__element bar--normal"
					 :class="{ 'graphic--hover' : chartHover }"
	>
		<div class="graphic__header">
			<h5>{{ graphicData.title }}</h5>
		</div>
		<div class="graphic__content">
			<div class="row">
				<div class="col-12 col-md-6">
					<div class="graphic__wrapper holder--bar">
						<Bar :options="chartOptions"
							 :data="chartData"
							 ref="barChart"
						/>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="chart__legend__wrapper">
						<div class="chart__legend">
							<div class="chart__percent h3"
									 :style="{ top: percentLineTopPos }"
							>
								{{ graphicData.rows[graphicData.rows.length - 1].percent || '' }}
								<div class="chart__percent-line"
										 :style="{ width: percentLineWidth }"
										 v-if="graphicData.rows[graphicData.rows.length - 1].percent"
								/>
							</div>
							<div class="chart__legend__inner font-small">
								<div class="chart__list-title">{{ graphicData.default_values }}</div>
								<div class="chart__list-entry__wrapper">
									<div v-for="(bar, index) in graphicData.rows"
										 @mouseover="legendMouseOverHandler(index)"
										 @mouseleave="legendMouseLeaveHandler()"
										 class="chart__list-entry"
										 :class="{ active: isActiveEntry(index) }"
									>
										<div class="graphic-dot"></div>
										<div class="chart__list-year">
											{{ bar.title }}
										</div>
										<div class="chart__list-percent">
											{{ bar.percent }}
										</div>
										<div class="chart__list-number">
											{{ bar.number ? numFormat.format(Number(bar.number)) : '' }}<span v-if="graphicData.value_suffix">{{ graphicData.value_suffix }}</span>
										</div>
									</div>
								</div>
							</div>
							<div v-if="graphicData.optional_text.length > 0"
									 class="chart__list-text font-small"
									 v-html="graphicData.optional_text"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script lang="ts" setup>
import {ref, onBeforeMount} from 'vue';
import {Bar} from 'vue-chartjs'
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

const barChart = ref<HTMLElement>();
const activeIndex = ref(null);
const chartHover = ref(false);
const percentLineWidth = ref('0');
const percentLineTopPos = ref('0');

const numFormat = new Intl.NumberFormat('de-CH', {maximumFractionDigits: 1})

const props = defineProps({
	chartData: {
		type: Object,
		required: true
	},
	chartOptions: {
		type: Object,
		default: () => {}
	},
	graphicData: {
		type: Object,
		default: () => {}
	}
});

const isActiveEntry = (index) => {
	return index == activeIndex.value;
}

const externalTooltipHandler = (context) => {
	const {chart, tooltip} = context;

	if (tooltip.opacity) {
	  activeIndex.value = tooltip.dataPoints[0].dataIndex;
		chartHover.value = true;
  }
  else {
	  activeIndex.value = null;
		chartHover.value = false;
  }
}

const legendMouseLeaveHandler = () => {
	activeIndex.value = null;
	barChart.value['chart'].setActiveElements([]);
}

const legendMouseOverHandler = (index) => {
	const chart = barChart.value['chart'];

	if (!chart.getActiveElements().length || chart.getActiveElements()[0].index != index) {
		chart.setActiveElements([]);
		chart.setActiveElements([
			{
				datasetIndex: 0,
				index: index,
			}
		]);

		chart.update();
	}
}

onBeforeMount(() => {
	props.chartOptions.plugins.tooltip = {
		enabled: false,
		position: 'nearest',
		external: externalTooltipHandler
	}

	props.chartOptions.scales.y.ticks = {
		callback: _ => ''
  }

	props.chartOptions.animation = {
		onComplete: function (animation) {
			if (animation.initial) {
				const meta = animation.chart.getDatasetMeta(animation.chart._metasets.length - 1);
				const chartEl = meta.data[meta.data.length - 1];

				percentLineWidth.value = 'calc(100%)';
				percentLineTopPos.value = 'calc(100% - ' + (chartEl.height + meta.yScale._margins.bottom - 1) + 'px';
			}
		}
	}

	props.chartOptions.plugins.datalabels.formatter = function (value, context) {
		return (value ? numFormat.format(value) : '');
	}
})
</script>

<style lang="scss">
</style>
