<template>
  <div class="grid" ref="grid">
    <slot/>
  </div>
</template>


<script lang="ts" setup>
import { computed, nextTick, PropType, ref, UnwrapRef, watch } from 'vue';
const props = defineProps({
  watch: {
    type: Array as PropType<Array<UnwrapRef<any>>>,
    required: false,
  }
});
const grid = ref<HTMLElement>();
const currentResizeObserverElements = ref<Array<any>>([]);
const gridResizeObserver = new ResizeObserver(items => {
  items.forEach(item => {
    resizeGridItem(item.target.parentElement);
  });
});
const allItems = () => {
  return Array.from(grid.value?.children ?? []);
};
const computedStyle = computed(() => window.getComputedStyle(grid.value));
/**
 * The trick is setting up repeating grid rows that are fairly short, letting the elements fall into the
 * grid horizontally as they may, then adjusting their heights to match the grid with some fairly light math to
 * calculate how many rows they should span.
 *
 * @see https://css-tricks.com/piecing-together-approaches-for-a-css-masonry-layout/
 * @param item
 */
function resizeGridItem(item: HTMLElement) {
  const rowHeight = parseInt(computedStyle.value.getPropertyValue('grid-auto-rows'));
  const rowGap = parseInt(computedStyle.value.getPropertyValue('grid-row-gap'));
  const columnGap = parseInt(computedStyle.value.getPropertyValue('grid-column-gap'));
  const innerItem = item.firstElementChild;
  const itemHeight = innerItem.getBoundingClientRect().height + rowGap;
  const rowSpan = Math.ceil(((itemHeight) / (rowHeight + rowGap)) + (columnGap / rowHeight));
  item.style.gridRowEnd = 'span ' + rowSpan;
}
function resizeAllGridItems() {
  allItems().forEach(item => resizeGridItem(item));
}
function applyResizeObserver() {
  // Unobserves any existing elements and then re-applies the elements to be observed
  currentResizeObserverElements.value.forEach(item => gridResizeObserver.unobserve(item));
  currentResizeObserverElements.value.splice(0, currentResizeObserverElements.value.length);
  const items = allItems().map(item => item.firstElementChild);
  currentResizeObserverElements.value.push(...items);
  items.forEach(item => {
    gridResizeObserver.observe(item, {box: 'content-box'});
  });
}
watch(props.watch, () => {
  // Wait for the elements to be rendered, then apply the observer
  nextTick(() => {
    applyResizeObserver();
    resizeAllGridItems();
  });
}, {
  immediate: true
});
</script>

<style lang="scss" scoped>
.grid {
  &::v-deep(> * > :first-child) {
    // Important to make the ResizeObserver work
    display: block;
  }
}
</style>
