<template>
  <div class="tab__filter">
    <template v-for="taxonomy in props.filter.taxonomies.flat(1)" :key="taxonomy">
      <div class="tab__item"
           v-for="term in taxonomy.terms"
           :key="term"
           :class="{active: isActive(taxonomy.slug, term.term_id)}"
           @click="setTaxonomyFilter(taxonomy.slug, term.term_id, true)"
      >
        {{ term.name }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { DiviTaxonomyDefinition, PaginatorFilter } from '../../composition/paginator';

const filterVisible = ref(false);

const props = defineProps({
  filter: {
    type: Object as PropType<PaginatorFilter>,
    required: true
  }
});

const {
  isActive,
  selectedFilters,
  resetFilter,
  setTaxonomyFilter,
} = props.filter;
</script>

<style>
.tab__item{
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2rem;
    text-decoration: underline;
}
.tab__item:hover{
    cursor: pointer;
}
</style>