<template>
  <article class="graphic__element bar--ring ring--proportion"
           :class="{ 'graphic--hover' : chartHover }"
  >
    <div class="graphic__header">
      <h5>{{ graphicData.title }}</h5>
    </div>
    <div class="graphic__content">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="graphic__wrapper holder--bar">
            <div class="doughnut__underlay">
              <div class="font-small text-center">
                {{ underlayData.title }}
              </div>
              <div class="h3">
                {{ formatValue(underlayData.value_one) }}
              </div>
              <div class="font-small text-center">
                {{ formatValue(underlayData.value_two) }}
              </div>
            </div>
            <Doughnut :options="chartOptions"
                      :data="chartData"
                      ref="doughnutChart"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="chart__legend__wrapper">
            <div class="chart__legend">
              <div class="chart__legend__inner font-small color--reverse"
                   :class="colorClass"
              >
                <div class="chart__list-title">
                  <span>
                    {{ graphicData.ring_year_one }}
                  </span>
                  <span v-if="graphicData.rows.find(row => row.number_two || row.percent_two)"
                        class="chart__list-title__second"
                  >
                      {{ graphicData.ring_year_two }}
                  </span>
                </div>
                <div class="chart__list-entry__wrapper">
                  <div v-for="(data, index) in graphicData.rows.slice().reverse()"
										 @mouseover="legendMouseOverHandler(index)"
										 @mouseleave="legendMouseLeaveHandler()"
										 class="chart__list-entry"
										 :class="{ active: isActiveEntry(index) }"
									>
                    <div class="graphic-dot"></div>
                    <div class="chart__list-year">
                      {{ data.title }}
                    </div>
                    <div class="chart__list-percent"
                         :class="{ 'two-year--true': data.number_two && data.percent_two }"
                    >
                      <div class="number-year">{{ data.percent_one }}%</div>
                      <div class="percent-year">{{ data.number_one }}</div>
                    </div>
                    <div v-if="data.number_two && data.percent_two"
                         class="chart__list-number"
                    >
                      <div class="number-year">{{ data.number_two }}</div>
                      <div class="percent-year">{{ data.percent_two }}%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="graphicData.optional_text.length > 0"
                   class="chart__list-text font-small"
                   v-html="graphicData.optional_text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
import {computed, onBeforeMount, ref} from 'vue';
import {Doughnut} from 'vue-chartjs'
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const doughnutChart = ref<HTMLElement>();
const activeIndex = ref(null);
const chartHover = ref(false);

const numFormat = new Intl.NumberFormat('de-CH', {maximumFractionDigits: 1})

const props = defineProps({
  chartData: {
    type: Object,
    required: true
  },
  chartOptions: {
    type: Object,
    default: () => {}
  },
  graphicData: {
    type: Object,
    default: () => {}
  }
});

const underlayData = computed(() => {
	let res = {
	  title: props.graphicData.ring_default_title,
	  value_one: props.graphicData.ring_default_value_one,
	  value_two: props.graphicData.ring_default_value_two,
  }

	if (activeIndex.value !== null) {
		const row = props.graphicData.rows[activeIndex.value];
		res.title = row.title;
		res.value_one = row.number_one;
		res.value_two = row.percent_one + '%';
  }

	return res;
});

const colorClass = computed(() => {
	let colorClass = 'color--';
	switch (props.graphicData.rows.length) {
      case 1:
		  case 3:
		    colorClass += 'green';
		    break;

		  case 2:
			  colorClass += 'blue';
		    break;

      default:
        colorClass += 'default';
  }

  return colorClass;
});

const formatValue = (value) => {
	const isPercentage = value.includes('%');
	value = value.replace('%', '')

	let result = '';
	if (value) {
		result = !isPercentage ? numFormat.format(Number(value)) : value + '%';
  }

	return result;
}

const isActiveEntry = (index) => {
	index = props.graphicData.rows.length - (index + 1);
	return index == activeIndex.value;
}

const externalTooltipHandler = (context) => {
	const {chart, tooltip} = context

  if (tooltip.opacity) {
	  chartHover.value = true;
	  activeIndex.value = tooltip.dataPoints[0].dataIndex;
  }
  else {
	  chartHover.value = false;
	  activeIndex.value = null;
  }
}

const legendMouseLeaveHandler = () => {
	activeIndex.value = null;
	doughnutChart.value['chart'].setActiveElements([]);
}

const legendMouseOverHandler = (index) => {
	index = props.graphicData.rows.length - (index + 1);

	const chart = doughnutChart.value['chart'];
	activeIndex.value = index;

	if (!chart.getActiveElements().length || chart.getActiveElements()[0].index != index) {
		chart.setActiveElements([]);
		chart.setActiveElements([
			{
				datasetIndex: 0,
				index: index,
			}
		]);

		chart.update();
	}
}

onBeforeMount(() => {
	props.chartOptions.plugins.tooltip = {
		enabled: false,
		position: 'nearest',
		external: externalTooltipHandler
	}
});
</script>

<style lang="scss">
</style>
