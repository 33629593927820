<template>
  <article class="graphic__element bar--segment"
           :class="{ 'graphic--hover' : chartHover }"
  >
    <div class="graphic__header">
      <h5>{{ graphicData.title }}</h5>
    </div>
    <div class="graphic__content">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="graphic__wrapper holder--bar" style="height: {{ graphicData.graphicHeight}}px;">
            <Bar :options="chartOptions"
                 :data="chartData"
                 ref="barSegmentChart"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="chart__legend__wrapper">
            <div class="chart__legend">
              <div class="chart__percent h3"
                   :style="{ top: percentLineTopPos }"
              >
                {{ graphicData.rows[graphicData.rows.length - 1].percent || '' }}
                <div class="chart__percent-line"
										 :style="{ width: percentLineWidth }"
                     v-if="graphicData.rows[graphicData.rows.length - 1].percent"
								/>
              </div>

              <template v-for="(row, rowIndex) in graphicData.rows">
                <div class="chart__legend__inner font-small"
                     :class="{ 'color--green': rowIndex == graphicData.rows.length -1 }"
                     v-show="isActiveRow(rowIndex)"
                >
                  <div class="chart__list-title chart__list-entry">
                    <div class="chart__list-year">
                      <span v-if="row.legend_title">{{ row.legend_title }}</span>
                      <span v-else>{{ row.year }}</span>
                    </div>
                    <div class="chart__list-percent">
                      {{ row.percent }}
                    </div>
                    <div class="chart__list-number" >
                      {{ row.number ? numFormat.format(Number(row.number)) : '' }}
                    </div>
                  </div>

                  <div class="chart__list-entry__wrapper">
                    <template v-for="(segment, segmentIndex) in (row.segments.slice().reverse())">
                      <div v-if="segment.percent > 0 || segment.value > 0"
                           @mouseover="legendMouseOverHandler(rowIndex, segmentIndex, row)"
                           @mouseleave="legendMouseLeaveHandler()"
                           class="chart__list-entry"
                           :class="{ active: isActiveEntry(rowIndex, segmentIndex, row) }"
                      >
                        <div class="graphic-dot"></div>
                        <div class="chart__list-year">
                          {{ segment.title }}
                        </div>
                        <div class="chart__list-percent">
                          <span v-if="segment.percent">{{ segment.percent }}%</span>
                        </div>
                        <div class="chart__list-number">
                          {{ segment.value ? numFormat.format(Number(segment.value)) : '' }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <div v-if="graphicData.optional_text.length > 0"
                   class="chart__list-text font-small"
                   v-html="graphicData.optional_text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>


<script lang="ts" setup>
import {computed, onBeforeMount, ref} from 'vue';
import {Bar} from 'vue-chartjs'
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

const props = defineProps({
  chartData: {
    type: Object,
    required: true
  },
  chartOptions: {
    type: Object,
    default: () => {
    }
  },
  graphicData: {
    type: Object,
    default: () => {
    }
  }
});

const defaultRowIndex = props.graphicData.rows.length - 1;

const chartHover = ref(false);
const barSegmentChart = ref<HTMLElement>();
const activeRowIndex = ref(defaultRowIndex);
const activeSegmentIndex = ref(null);
const percentLineWidth = ref('0');
const percentLineTopPos = ref('0')

const numFormat = new Intl.NumberFormat('de-CH', {maximumFractionDigits: 1})

const isActiveRow = (rowIndex) => {
	return rowIndex == activeRowIndex.value;
}
const isActiveEntry = (rowIndex, segmentIndex, row) => {
	const reverseSegmentIndex = (row.segments.length - 1) - segmentIndex;
	return rowIndex == activeRowIndex.value && reverseSegmentIndex == activeSegmentIndex.value;
}

const externalTooltipHandler = (context) => {
	const {chart, tooltip} = context;

	if (tooltip.opacity) {
		chartHover.value = true;
	  activeRowIndex.value = tooltip.dataPoints[0].dataIndex;
	  activeSegmentIndex.value = tooltip.dataPoints[0].datasetIndex;
  }
  else {
	  chartHover.value = false;
	  activeRowIndex.value = defaultRowIndex;
	  activeSegmentIndex.value = null;
  }
}

const legendMouseLeaveHandler = () => {
	activeRowIndex.value = defaultRowIndex;
	activeSegmentIndex.value = null;
	barSegmentChart.value['chart'].setActiveElements([]);
}

const legendMouseOverHandler = (rowIndex, segmentIndex, row) => {
	const chart = barSegmentChart.value['chart'];
	const activeElements = chart.getActiveElements();
	const reverseSegmentIndex = (row.segments.length - 1) - segmentIndex;

	if (!activeElements.length || (activeElements[0].index != rowIndex && activeElements[0].datasetIndex != reverseSegmentIndex)) {
		chart.setActiveElements([]);
		chart.setActiveElements([{
      datasetIndex: reverseSegmentIndex,
      index: rowIndex,
    }]);

		chart.update();
	}
}

onBeforeMount(() => {
	props.chartOptions.plugins.tooltip = {
		enabled: false,
		position: 'nearest',
		external: externalTooltipHandler
	}

	props.chartOptions.scales.y.ticks = {
		callback: _ => ''
  }

	props.chartOptions.animation = {
		onComplete: function (animation) {
			if (animation.initial) {
		    let barHeight = 0;
		    const meta = animation.chart.getDatasetMeta(animation.chart._metasets.length - 1);

				animation.chart._metasets.forEach((metaset) => {
					barHeight += metaset.data[metaset.data.length - 1].height;
        })

				percentLineWidth.value = 'calc(100%)';
				percentLineTopPos.value = 'calc(100% - ' + (barHeight + meta.yScale._margins.bottom - 1) + 'px';
			}
		}
	}

	props.chartOptions.plugins.datalabels.formatter = function (value, context) {
	  let label = 0;
	  const datasets = context.chart.data.datasets;
		const arrayColumn = datasets.map(x => x.data[context.dataIndex]);

		if (arrayColumn.pop() == value) {
			datasets.forEach(dataset => {
				label += dataset.data[context.dataIndex];
			});
		}

		return (label ? numFormat.format(label) : '');
	}
})
</script>

<style lang="scss">
</style>
