<template>
  <article class="graphic__element bar--number" data-equalizer-watch="graphic">
    <div class="graphic__header">
      <h5>{{ graphicData.title }}</h5>
    </div>

    <div class="graphic__content">
      <div class="number__box__wrapper">
        <div class="number__box h2"
             v-for="entry in filteredNumbers"
        >
          <div class="iCountUp">
            <count-up
              :end-val="entry.number"
              :options="options"
              :duration="2"
              :decimal-places="getDecimalPlaces(entry.number)"
              :loop="0"
              :delay="0.5"
            >
              <template #prefix>
                <span class="graphic-number-prefix">{{ entry.prefix }}</span>
              </template>
              <template #suffix>
                <span class="text-unit h4">{{ entry.unit }}</span>
              </template>
            </count-up>
          </div>
        </div>
      </div>

      <div v-if="graphicData.optional_text.length > 0"
           class="chart__list-text font-small"
           v-html="graphicData.optional_text"
      />
    </div>
  </article>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CountUp from 'vue-countup-v3'
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'

ChartJS.register(Title, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
  graphicData: {
    type: Object,
    default: () => {}
  }
});

const options = {
	separator: '’',
  useGrouping: true,
	enableScrollSpy: true,
	scrollSpyOnce: true,
  decimal: ',',
}

const filteredNumbers = computed(() => {
  return props.graphicData.number.filter((el) => !!el.number)
})

const getDecimalPlaces = (n) => {
  const arr = n.toString().split('.');
	return (arr.length > 1 ? arr[1].length : 0);
}

</script>

<style lang="scss">
</style>
