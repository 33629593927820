<template>
    <div class="basic__filter">
        <div class="filter__inner">
            <div class="filter-header">
                <button @click="filterVisible = !filterVisible" class="button--select" :class="{ active: filterVisible }">
                    <span>{{ $t('filter_by_category') }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.945" height="8.689" viewBox="0 0 14.945 8.689">
                        <path d="M0 1.42 1.408 0l5.959 5.91L13.571.047l1.374 1.454-7.612 7.188Z" fill="#1057a3" />
                    </svg>
                </button>
            </div>

            <div v-if="filterVisible" class="filter__content">
                <div class="filter__block">
                    <div class="filter__item">
                        <div
                            class="filter__item__inner"
                            @click="
                                () => {
                                    resetFilter();
                                    hideFilter();
                                }
                            "
                        >
                            {{ $t('Reset Filter') }}
                        </div>
                    </div>
                    <div
                        v-for="term in props.filter.taxonomies
                            .flat(1)
                            .map((tax) => tax.terms)
                            .flat(1)"
                        :key="term"
                        class="filter__item"
                    >
                        <div
                            class="filter__item__inner"
                            :class="{ active: isActive(term.taxonomy, term.term_id) }"
                            @click="
                                () => {
                                    setTaxonomyFilter(term.taxonomy, term.term_id);
                                    hideFilter();
                                }
                            "
                        >
                            {{ term.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { PaginatorFilter } from '../../composition/paginator';

const filterVisible = ref(false);

const props = defineProps({
    filter: {
        type: Object as PropType<PaginatorFilter>,
        required: true,
    },
});

const hideFilter = () => (filterVisible.value = false);

const { isActive, selectedFilters, resetFilter, setTaxonomyFilter } = props.filter;
</script>
