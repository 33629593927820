import cssVars from 'css-vars-ponyfill';

cssVars({
    silent: true,
    onSuccess(cssText, element, url) {
        // Replace CSS variables inside inline SVGs
        if (cssText.includes('WLY Theme Colors')) {
            const variables = {};

            // Extract all color variables form the CSS and put them into an object
            for (let match of cssText.matchAll(/(--color-.*):\s*(.*);/gi)) {
                variables[`var(${match[1]})`] = match[2];
            }

            // Find all nodes that should be replaced
            const svgElements = document.querySelectorAll('svg [fill^="var("]');

            // Replace all fill attributes with the actual color
            for (let svg of svgElements) {
                const color = svg.getAttribute('fill');

                if (variables.hasOwnProperty(color)) {
                    svg.setAttribute('fill', variables[color]);
                }
            }
        }

        return cssText;
    }
});

