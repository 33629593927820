<template>
    <div class="buttons--filter">
        <div class="button" @click="filter.resetFilter()">Alle</div>
        <template v-for="taxonomy in props.filter.taxonomies.flat(1)" :key="taxonomy">
            <div
                class="button"
                v-for="term in taxonomy.terms"
                :key="term"
                :class="{ active: isActive(taxonomy.slug, term.term_id) }"
                @click="setTaxonomyFilter(taxonomy.slug, term.term_id)"
            >
                {{ term.name }}
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { PaginatorFilter } from '../../composition/paginator';

const props = defineProps({
    filter: {
        type: Object as PropType<PaginatorFilter>,
        required: true,
    },
});

const { isActive, selectedFilters, resetFilter, setTaxonomyFilter } = props.filter;
</script>

<style>
.tab__item {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2rem;
    text-decoration: underline;
}
.tab__item:hover {
    cursor: pointer;
}
</style>
