const callbacks: Array<() => any> = [];

document.addEventListener('DOMContentLoaded', () => {
    run();
});

document.addEventListener('readystatechange', (value) => {
    if (document.readyState === 'complete') {
        run();
    }
});

export const onReady = (callback: () => any) => {
    if (typeof callback !== 'function') {
        throw Error('Callback must be function.');
    }

    callbacks.push(callback);

    if (document.readyState === 'complete') {
        run();
    }
};

const run = () => {
    while (callbacks.length > 0) {
        callbacks.shift()();
    }
};
