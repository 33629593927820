import {registerCustomElements} from "./custom-elements";

import makeVue from './vue'

registerCustomElements();

const nodes = document.querySelectorAll('[data-vue-instance]');

if (nodes.length > 0) {
    for (const node of nodes) {
        makeVue(node);
    }
}